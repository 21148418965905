import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "admin-panel-nav-menu" }
const _hoisted_2 = { class: "admin-panel-nav-menu__container" }
const _hoisted_3 = { class: "admin-panel-nav-menu__select-wrap" }
const _hoisted_4 = { class: "admin-panel-nav-menu__select-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SelectArrow = _resolveComponent("SelectArrow")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_link, {
        to: "/admin/analytics/home",
        class: "admin-panel-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("Analytics")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/admin/instructors",
        class: "admin-panel-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createTextVNode("Instructors")
        ])),
        _: 1
      }),
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "admin-panel-nav-menu__select-title",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openedSelect()))
        }, [
          _cache[4] || (_cache[4] = _createElementVNode("span", null, "Billing", -1)),
          _createVNode(_component_SelectArrow)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["admin-panel-nav-menu__select-body", { 'open-select': this.isOpenSelect }])
        }, [
          (
              this.isFinancialManager === 'true' &&
              this.isSubscriptionEnabled === 'true'
            )
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: "/admin/payments-log",
                class: "admin-panel-nav-menu__menu-item-select"
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode("Payments Log")
                ])),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_router_link, {
            to: "/admin/billing-activity",
            class: "admin-panel-nav-menu__menu-item-select"
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Billing activity")
            ])),
            _: 1
          })
        ], 2)
      ])), [
        [_directive_click_outside, _ctx.closedSelect]
      ]),
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "admin-panel-nav-menu__select-title",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openedSelectStudents()))
        }, [
          _cache[7] || (_cache[7] = _createElementVNode("span", null, "Students", -1)),
          _createVNode(_component_SelectArrow)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["admin-panel-nav-menu__select-body", { 'open-select': this.isOpenSelectStudents }])
        }, [
          _createVNode(_component_router_link, {
            to: "/admin/students",
            class: "admin-panel-nav-menu__menu-item-select"
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode("Students")
            ])),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: "/admin/students-history",
            class: "admin-panel-nav-menu__menu-item-select"
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode("Students' history")
            ])),
            _: 1
          })
        ], 2)
      ])), [
        [_directive_click_outside, _ctx.closedSelectStudents]
      ]),
      _createVNode(_component_router_link, {
        to: "/admin/skill-achievement-log",
        class: "admin-panel-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[10] || (_cache[10] = [
          _createTextVNode("Skills log")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/admin/stickers",
        class: "admin-panel-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[11] || (_cache[11] = [
          _createTextVNode("Stickers")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/admin/motivational-cards",
        class: "admin-panel-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[12] || (_cache[12] = [
          _createTextVNode("Motivational cards")
        ])),
        _: 1
      }),
      _createVNode(_component_router_link, {
        to: "/admin/settings",
        class: "admin-panel-nav-menu__menu-item"
      }, {
        default: _withCtx(() => _cache[13] || (_cache[13] = [
          _createTextVNode("Settings")
        ])),
        _: 1
      })
    ])
  ]))
}