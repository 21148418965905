
import { defineComponent } from "vue";
import vClickOutside from "click-outside-vue3";
import SelectArrow from "@/assets/svg/select-arrow.svg?inline";
export default defineComponent({
  name: "AdminPanelNavMenu",
  data() {
    return {
      isFinancialManager: localStorage.getItem("financial-manager"),
      isSubscriptionEnabled: localStorage.getItem("is-subscription-enabled"),
      isOpenSelect: false,
      isOpenSelectStudents: false,
    };
  },
  components: {
    SelectArrow,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    openedSelect() {
      this.isOpenSelect = true;
    },
    closedSelect() {
      this.isOpenSelect = false;
    },
    openedSelectStudents() {
      this.isOpenSelectStudents = true;
    },
    closedSelectStudents() {
      this.isOpenSelectStudents = false;
    },
  },
});
