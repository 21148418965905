
import { defineComponent } from "vue";
import AdminPanelNavMenu from "@/components/molecules/AdminPanelNavMenu.vue";
import DateRangePicker from "@/components/molecules/DateRangePicker.vue";
import SortArrow from "@/assets/svg/sort-arrow.svg?inline";
import SelectArrow from "@/assets/svg/select-arrow.svg?inline";
import AlertBlock from "@/components/molecules/AlertBlock.vue";
import Pagination from "@/components/molecules/Pagination.vue";
import Select from "@/components/atoms/CustomSelect.vue";
import Calendar from "@/components/molecules/Calendar.vue";
import vClickOutside from "click-outside-vue3";
export default defineComponent({
  name: "StudentsHistory",
  data() {
    return {
      page: 1,
      instructorsData: {},
      dataAllStudents: [],
      selectedNewDate: null,
      instructorsMeta: null,
      errorInstructors: null,
      sortData: "",
      isCalendarVisible: false,
      filters: "",
      dataNotification: [],
      period: 1,
    };
  },
  components: {
    AdminPanelNavMenu,
    SortArrow,
    AlertBlock,
    Pagination,
    Select,
    Calendar,
    SelectArrow,
    DateRangePicker,
  },
  watch: {
    searchData() {
      this.getHistoryStudents();
      this.page = 1;
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mounted() {
    this.getEventTypes();
    this.getStudents();
    this.getHistoryStudents();
    this.getEventTypes();
  },
  methods: {
    getHistoryStudents(params, sort) {
      let startDate = "";
      let endDate = "";
      const currentDate = new Date();
      const pastDate = new Date();
      if (this.period === 1) {
        pastDate.setDate(currentDate.getDate() - 7);
      } else if (this.period === 2) {
        pastDate.setDate(currentDate.getDate() - 30);
      } else if (this.period === 3) {
        pastDate.setDate(currentDate.getDate() - 1000);
      }
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      };
      startDate =
        this.selectedStartDate !== undefined
          ? this.selectedStartDate
          : formatDate(pastDate);
      endDate =
        this.selectedEndDate !== undefined
          ? this.selectedEndDate
          : formatDate(currentDate);
      if (sort === undefined) {
        sort = "date";
      }
      params = `?page=${this.page}&sort=${sort}${this.filters}&start_date=${startDate}&end_date=${endDate}`;
      this.$store.dispatch("students/getHistoryStudents", params).then(
        (res) => {
          this.instructorsData = res.data;
          this.instructorsMeta = res.meta;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    getEventTypes() {
      this.$store.dispatch("students/getEventTypes").then(
        (res) => {
          this.dataNotification = Object.entries(res.data).map(
            ([key, value]) => ({
              title: value,
              value: key,
            })
          );
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    getStudents() {
      const params = "?n=1000";
      this.$store.dispatch("students/getStudents", params).then(
        (res) => {
          this.dataAllStudents = res.data;
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    getEventTypes() {
      this.$store.dispatch("students/getEventTypes").then(
        (res) => {
          this.dataNotification = Object.entries(res.data).map(
            ([key, value]) => ({
              title: value,
              value: key,
            })
          );
          console.log(this.dataNotification, "this.dataNotification");
        },
        (error) => {
          this.errorInstructors = error.response.data.errors;
        }
      );
    },
    handlePage(page) {
      this.page = page;
      this.getHistoryStudents();
    },
    toggleCalendar() {
      this.isCalendarVisible = !this.isCalendarVisible;
    },
    closeCalendar() {
      this.isCalendarVisible = false;
    },
    handleOptionSelectedStudentName(selectedOption) {
      console.log(selectedOption);
      this.filters =
        this.filters + `&filter[student_name]=${selectedOption.id}`;
      this.getHistoryStudents();
    },
    handleOptionSelectedEventType(selectedOption) {
      this.filters =
        this.filters + `&filter[event_type]=${selectedOption.value}`;
      this.getHistoryStudents();
    },
    selectDateFill(date, month, year) {
      const selectedDate = new Date(year, month, date.value);
      const currentDate = new Date();
      if (selectedDate > currentDate) {
        return;
      }
      if (date.value !== "") {
        const selectedDate = new Date(year, month, date.value);
        const Month = selectedDate.getMonth() + 1;
        let monthFormat = "";
        if (Month < 10) {
          monthFormat = "0" + (selectedDate.getMonth() + 1);
        } else monthFormat = (selectedDate.getMonth() + 1).toString();
        const Day = selectedDate.getDate();
        let dayFormat = "";
        if (Day < 10) {
          dayFormat = "0" + selectedDate.getDate();
        } else dayFormat = selectedDate.getDate().toString();
        if (!this.selectedStartDate) {
          this.selectedStartDate =
            selectedDate.getFullYear() + "-" + monthFormat + "-" + dayFormat;
        } else if (
          !this.selectedEndDate &&
          selectedDate.getFullYear() + "-" + monthFormat + "-" + dayFormat >=
            this.selectedStartDate
        ) {
          this.selectedEndDate =
            selectedDate.getFullYear() + "-" + monthFormat + "-" + dayFormat;
        } else {
          this.selectedStartDate =
            selectedDate.getFullYear() + "-" + monthFormat + "-" + dayFormat;
          this.selectedEndDate = null;
        }
      }
      if (this.selectedEndDate) {
        const filterKey = "filter[date_range]=";
        let filtersArray = this.filters.split("&");
        filtersArray = filtersArray.filter(
          (filter) => !filter.startsWith(filterKey)
        );
        filtersArray.push(
          `${filterKey}${this.selectedStartDate},${this.selectedEndDate}`
        );
        this.filters = filtersArray.join("&");
        this.getHistoryStudents();
      }
    },
    selectStaticRange(period) {
      this.period = period;
      this.getHistoryStudents();
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${day}.${month}.${year}, ${hours}:${minutes}`;
    },
  },
});
